import React from "react";

const MediaKitAssets = () => (
  <div className="col-12">
    <h2 className="f_p f_size_30 l_height30 f_600 t_color3 mb_20 text-center">
      Hexomatic Logo and Colors
    </h2>
    <p>
      Please don't modify the logo and avoid using it in a confusing way,
      including suggesting sponsorship or endorsement by Hexomatic or Hexact, or
      in a way that confuses Hexomatic or Hexact with another brand (including
      your own). By using the Hexomatic logo you agree to follow these
      guidelines as well as our Terms of Service and all our rules and policies.
      Hexact reserves the right to cancel, modify or change the permission in
      these guidelines at any time at its sole discretion.
    </p>
  </div>
);

export default MediaKitAssets;
