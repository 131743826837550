import React from "react";
import Layout from "../../components/layout/layout";
import MediaKitAssets from "../../components/media-kit-assets";
import MediaKitColorBoxes from "../../components/media-kit-colors";
import MediaKitTabs from "../../components/mediaKitTabs";
import { PageHero } from "../../components/page-hero";
import Section from "../../components/section";

const MediaKit = () => {
  const colors = [
    {
      RGB: "(168, 1, 255)",
      CMYK: "(57, 80, 0, 0)",
      HEX: "#A801FF",
    },
    {
      RGB: "(50, 53, 55)",
      CMYK: "(2, 1, 0, 78)",
      HEX: "#323537",
    },
  ];
  return (
    <Layout>
      <PageHero
        title="MEDIA_KIT_PAGE_TITLE"
        subtitle="MEDIA_KIT_PAGE_SUBTITLE"
        titleSmall
        className="special-section"
      />
      <Section about>
        <MediaKitTabs />
        <MediaKitAssets />
        <MediaKitColorBoxes colors={colors} />
      </Section>
    </Layout>
  );
};

export default MediaKit;
